import React from "react";
import PropTypes from "prop-types";
import { Container } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";
import Heading from "@ui/heading";
import Button from "@ui/button";
import { SectionWrap, InnovationImgWrap, InnovationListWrap, InnovationListItem, AndovarAcademyWrap, HumanTouchBox, HoverContent, InnovationDesc, InnovationContentBox } from "./style";
const TechnologyInnovation = ({ data }) => {
    return (
        <SectionWrap id={data.section}>
            <Container>
                <SectionTitle
                    title={data.section_title?.title}
                    subtitle={data.section_title?.subtitle}
                    description={data.section_title?.description}
                />
                {data.images && data.images.length > 0 && (
                    <InnovationImgWrap>
                        <Image
                            src={data.images[0]?.src}
                            alt={data.images[0]?.alt || "Image"}
                            title={data.images[0]?.alt || "Image"}
                        />
                    </InnovationImgWrap>
                )}

                <InnovationListWrap>
                    {data &&
                        data.items.map((el, index) => (
                            <InnovationListItem key={index} href={el.path} title={el.title} target="_blank">
                                <InnovationContentBox className="innovationContentBox">
                                    <Image
                                        src={el.images[0]?.src}
                                        alt={el.images[0]?.alt || "Icon"}
                                        title={el.images[0]?.alt || "Icon"}
                                    />
                                    <Heading as="h4" className="innovationHeading">{el.title}</Heading> 
                                </InnovationContentBox>
                                <HoverContent className="hoverContent">
                                    <Heading as="h4" className="innovationHeading">{el.title}</Heading>
                                    <InnovationDesc>
                                        {el.description}
                                    </InnovationDesc>
                                </HoverContent>
                            </InnovationListItem>
                        ))}
                </InnovationListWrap>
                <HumanTouchBox>
                    {data?.headings?.[1] && (
                        <Heading as={data.headings[1]?.level}>
                            {data.headings[1]?.content}
                        </Heading>
                    )}
                </HumanTouchBox>
            </Container>
            <AndovarAcademyWrap>
                {data?.headings?.[0] && (
                    <Heading as={data.headings[0]?.level}>
                        {data.headings[0]?.content}
                    </Heading>
                )}
                {data?.buttons?.map(({ id, index, path, content, ...rest }) => (
                    <Button key={id} path={path} title={content} {...rest} className="academyBtn">
                        {content}
                    </Button>
                ))}
            </AndovarAcademyWrap>
        </SectionWrap>
    );
};
TechnologyInnovation.propTypes = {
    items: PropTypes.object,
};
export default TechnologyInnovation;