import styled, { device } from "@styled";

export const SectionWrap = styled.section`
    padding:30px 0 0;
    ${device.small} {
        padding:60px 0 0;
    }
    ${device.large} {
        padding:80px 0 0;
    }
    ${device.xlarge} {
        padding:100px 0 0;
    }
`;
export const InnovationImgWrap = styled.div`
    text-align:center;
`
export const InnovationListWrap = styled.div`
    padding-top:40px;
    display:grid;
    grid-template-columns:repeat(1, 1fr);
    grid-gap:10px;
    align-items:center;
    ${device.small} {
        grid-template-columns:repeat(2, 1fr);
        grid-template-rows:repeat(3, 1fr);
        padding-top:60px;
    }
    ${device.medium} {
        grid-template-columns:repeat(3, 1fr);
        grid-template-rows:repeat(2, 1fr);
    }
    ${device.large} {
        padding-top:80px;
        grid-template-columns:repeat(4, 1fr);
        grid-template-rows:repeat(2, 1fr);
    }
    ${device.xlarge} {
        padding-top:100px;
        grid-gap:20px;
    }
`
export const AndovarAcademyWrap = styled.div`
    background-color: #fa4f19;
    padding:30px 0;
    margin-top:40px;
    text-align:center;
    ${device.medium}{
        padding:50px 0;
        margin-top:60px;
    }
    ${device.xlarge}{
        padding:80px 0;
        margin-top:100px;
    }
    h4 {
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 1.5;
        color: #ffffff;
        font-weight: 700;
        ${device.medium}{
            font-size: 32px;
        }
        ${device.xlarge}{
            font-size: 40px;
        }
    }
    .academyBtn {
        background-color: transparent;
        border-color: transparent;
        padding: 0;
        border-radius: 0;
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 1.5;
        color: #ffffff;
        font-weight: 400;
        transition:all .5s ease-in-out;
        height:auto;
        &:hover{
            text-decoration:underline;
            color:#fff;
        }
        ${device.medium}{
            font-size: 32px;
        }
        ${device.xlarge}{
            font-size: 40px;
        }
    }
`
export const HumanTouchBox = styled.div`
    border-radius: 10px;
    background-color: #2f2062;
    border: 1px solid rgba(255,255,255,0.55);
    padding:20px 0;
    margin-top:40px;
    text-align:center;
    position:relative;
    width: calc(100% - 8px);
    ${device.small}{
        width: calc(100% - 10px);
    }
    &::after {
        content: "";
        position: absolute;
        bottom: -8px;
        right: -8px;
        width: 100%;
        max-width: 100px;
        height: calc(100% - 25px);
        background-color: rgb(250, 79, 25);
        border-radius: 10px;
        z-index: -1;
        ${device.small}{
            bottom: -10px;
            right: -10px;
            max-width: 150px;
            height: calc(100% - 30px);
        }
        ${device.large}{
            max-width: 194px;
            height: calc(100% - 43px);
        }
    }
    ${device.medium}{
        padding:30px 0;
        margin-top:60px;
    }
    ${device.xlarge}{
        padding:48px 0;
        margin-top:80px;
    }
    h4 {
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 1.2;
        color: #ffffff;
        font-weight: 700;
        ${device.medium}{
            font-size: 32px;
        }
        ${device.xlarge}{
            font-size: 40px;
        }
    }
    .humanBtn {
        border-radius: 0;
        font-size: 24px;
        letter-spacing: 1px;
        line-height: 1.5;
        color: #ffffff;
        font-weight: 700;
        transition:all .5s ease-in-out;
        &:hover{
            text-decoration:underline;
            color:#fff;
        }
        ${device.medium}{
            font-size: 32px;
        }
        ${device.xlarge}{
            font-size: 40px;
        }
    }
`
export const InnovationContentBox = styled.div`
    background-image: linear-gradient(0deg, #15054f 0%, #561783 99%, #561783 100%);
    border: 1px solid rgba(210,210,210,0.55);
    border-radius: 10px;
    height: 100%;
    min-height: 175px;
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    padding: 25px 15px 20px;
    transition:all .7s ease-in-out;
    width:100%;
    position:relative;
    z-index:1;
    transition:all .7s ease-in-out;
    ${device.medium}{
        min-height: 200px;
    }
    ${device.xlarge}{
        min-height: 232px;
        padding: 35px 20px 25px;
    }
    img{
        max-width:65px !important;
        width: auto;
        ${device.medium}{
            max-width:75px !important;
        }
    }
`
export const HoverContent = styled.div`
    background-image: linear-gradient(rgb(249, 79, 25), rgb(249, 79, 25));
    position: absolute;
    top: 0;
    left:0;
    height: 100%;
    z-index: -1;
    border-radius: 10px;
    transform: rotateY(180deg);
    transition:all .7s ease-in-out;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 25px 15px 20px;
    grid-gap:15px;
    width:100%;
    ${device.xlarge}{
        padding: 35px 25px 25px;
        grid-gap:18px;
    }
    .innovationHeading{
        font-weight: 600 !important;
    }
`
export const InnovationListItem = styled.a`
    border-radius: 10px;
    min-height: 175px;
    position:relative;
    overflow:hidden;
    display:block;
    transition:all .7s ease-in-out;
    ${device.medium}{
        min-height: 200px;
    }
    ${device.large} {
        &:nth-child(2), &:nth-child(5), &:nth-child(8){
            grid-row:2;
        }
        &:nth-child(3){
            grid-row:1 / span 2;
            grid-column: 2;
        }
        &:nth-child(6){
            grid-row:1 / span 2;
            grid-column: 3;
        }
        
    }
    ${device.xlarge}{
        min-height: 232px;
    }
    .innovationHeading{
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 1.3;
        color: #ffffff;
        margin:0;
        font-weight:400;
        transition:all .5s ease-in-out;
        text-align:left;
        ${device.large}{
            font-size:22px;
            width:90%;
        }
        ${device.xlarge}{
            font-size:24px;
        }
    }
    &:hover{
        transform:rotateY(180deg);
        .innovationContentBox{
            opacity:0;
            visibility:hidden;
        }
        .hoverContent{
            z-index:1;
        }
    }
    .innovationContentBox{
        img{
            object-fit:contain !important;
        }
    }
`
export const InnovationDesc = styled.p`
    font-size: 17px;
    line-height: 1.3;
    color: #ffffff;
    max-width:300px;
    ${device.xlarge}{
        width:80%;
    }
    ${device.xlarge}{
        width:70%;
    }
`